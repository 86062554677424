<template>
  <div class="sign">
    <Container>
      <Card padding="large">
        <Margins>
          <CardHeader
            :header="$t('REVIEW_CONTRACT')"
            :info="$t('REVIEW_CONTRACT_INFO')"
          />
          <SignYourPlan />
          <SignCustomerInfo />
          <ApplyAssetCard />]
          <Margins class="sign-text-container">
            <YourPlanTable showPeriod class="margins__double" />
            <SignTerms class="margins__double" />
            <SignContract />
            <Separator size="large" hasLine />
          </Margins>

          <b-row align-h="center">
            <b-col sm="5" lg="3">
              <Button
                @click="signWithBankId"
                :loading="isLoading"
                block
                variant="inverse-primary"
                >{{ $t('SIGN_WITH_BANK_ID') }}</Button
              >
            </b-col>
          </b-row>
        </Margins>
      </Card>
    </Container>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { SIGN_WITH_BANK_ID } from '@/types';
import {
  Container,
  Card,
  Margins,
  Button,
  CardHeader,
  Separator,
} from '@/components';
import {
  SignYourPlan,
  SignCustomerInfo,
  SignTerms,
  SignContract,
} from '@/containers/Sign';
import { ApplyAssetCard, YourPlanTable } from '@/containers/Apply';
import { constants } from '@/mixins';

export default {
  name: 'Sign',
  mixins: [constants],
  components: {
    Container,
    Card,
    Margins,
    Button,
    CardHeader,
    Separator,
    SignYourPlan,
    SignCustomerInfo,
    YourPlanTable,
    SignTerms,
    SignContract,
    ApplyAssetCard,
  },
  methods: {
    ...mapActions([SIGN_WITH_BANK_ID]),
    signWithBankId() {
      this.SIGN_WITH_BANK_ID();
    },
  },
  computed: {
    ...mapState(['isLoading']),
  },
};
</script>

<style lang="scss">
.sign-text-container {
  @include min-width(md) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  @include min-width(md) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
</style>
