<template>
  <Card padding="medium" color="grey" class="margins__double">
    <b-row align-v="center">
      <b-col sm="auto">
        <ContractSVG class="icon icon--large" />
      </b-col>
      <b-col>
        <Margins>
          <h6 class="text-normal">{{ $t('YOUR_PLAN') }}</h6>
          <YourPlanList />
        </Margins>
      </b-col>
    </b-row>
  </Card>
</template>

<script>
import { Card, Margins } from '@/components';
import YourPlanList from '@/containers/Apply/ApplyYourPlan/YourPlanList.vue';
import ContractSVG from '@/assets/images/contract.svg';

export default {
  name: 'SignYourPlan',
  components: {
    Card,
    Margins,
    YourPlanList,
    ContractSVG,
  },
};
</script>
