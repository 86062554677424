<template>
  <Card padding="medium" color="grey">
    <b-row align-v="center">
      <b-col sm="auto">
        <UserSVG class="icon h1" />
      </b-col>
      <b-col>
        <b-row align-h="between" align-v="center">
          <b-col cols="auto">
            <p>
              {{ loanApplicationData.firstName }}
              {{ loanApplicationData.lastName }}
            </p>
            <p class="text-extra-small text-grey">
              {{ $t('FORM.ID_NUMBER') }}:
              {{ loanApplicationData.identificationNumber }}
            </p>
          </b-col>
          <b-col cols="auto">
            <b-row>
              <b-col cols="auto">{{ loanApplicationData.email }}</b-col>
              <b-col cols="auto">{{ loanApplicationData.mobile }}</b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </Card>
</template>

<script>
import { mapState } from 'vuex';
import { Card } from '@/components';
import UserSVG from '@/assets/images/user.svg';

export default {
  name: 'SignCustomerInfo',
  components: {
    Card,
    UserSVG,
  },
  computed: {
    ...mapState(['loanApplicationData']),
  },
};
</script>
