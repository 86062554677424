<template>
  <b-row align-v="center">
    <b-col cols="12" sm="12" md="12">
      <List
        :items="bulletsList"
        variant="numbers"
        class="text-small text-grey"
      />
    </b-col>
  </b-row>
</template>

<script>
import { mapState } from 'vuex';
import { List } from '@/components';
import { constants } from '@/mixins';
import { getDynamicTranslation } from '@/utils';

export default {
  name: 'YourPlanList',
  mixins: [constants],
  components: {
    List,
  },
  computed: {
    ...mapState(['selectedBundle']),
    bulletsList() {
      if (this.$i18n.locale === this.LOCALES.FI) {
        return getDynamicTranslation(this.$i18n, 'YOUR_PLAN_BULLETS_LIST', []);
      }

      const { totalPeriod, upgradePeriod } = this.selectedBundle.bundle;

      return [
        this.$t('YOUR_PLAN_BULLETS_LIST.ITEM_1', [totalPeriod]),
        this.$t('YOUR_PLAN_BULLETS_LIST.ITEM_2', [upgradePeriod]),
        this.$t('YOUR_PLAN_BULLETS_LIST.ITEM_3', [totalPeriod]),
      ];
    },
  },
};
</script>
