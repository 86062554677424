<template>
  <div class="sign-terms">
    <b-row>
      <b-col
        md="6"
        v-for="(term, key) in individualizedTerms"
        :key="key"
        class="sign-terms__col"
      >
        <Button block variant="inverse" @click="showModal(term)">{{
          $t(`TERMS.${term.termsDocument.name}`)
        }}</Button>
      </b-col>
    </b-row>

    <b-modal
      :ref="modalRef"
      title="Terms modal"
      size="lg"
      centered
      hide-footer
      hide-header
    >
      <button type="button" class="modal__close" @click="hideModal">
        <CloseSVG class="icon" />
      </button>
      <Margins>
        <h6 class="text-normal">
          {{
            $t(
              `TERMS.${
                selectedTerms.termsDocument
                  ? selectedTerms.termsDocument.name
                  : 'Common Terms'
              }`,
            )
          }}
        </h6>
        <iframe
          :src="(selectedTerms.termsDocument || {}).pdfData"
          :title="(selectedTerms.termsDocument || {}).name"
          class="sign-terms__pdf"
        />
      </Margins>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { Margins, Button } from '@/components';
import { GET_INDIVIDUALIZED_TERMS } from '@/types';
import { constants } from '@/mixins';
import CloseSVG from '@/assets/images/times-circle-regular.svg';
import { commonTermsMock, isekkiTerms } from '@/mocks/TermDocumentMocks';

export default {
  name: 'SignTerms',
  mixins: [constants],
  components: {
    Margins,
    Button,
    CloseSVG,
  },
  data() {
    return {
      selectedTerms: {},
    };
  },
  mounted() {
    this.INDIVIDUALIZED_TERMS_TYPES.forEach((type) => {
      const hasThisTypeOfTerms = this.individualizedTerms.some(
        (term) => term.termsResponseType === type,
      );

      if (!hasThisTypeOfTerms) {
        this.GET_INDIVIDUALIZED_TERMS(
          type === 'COMMON' ? commonTermsMock : isekkiTerms,
        );
      }
    });
  },
  methods: {
    ...mapActions([GET_INDIVIDUALIZED_TERMS]),
    getCurrentTerms(terms) {
      return this.individualizedTerms.find(
        (t) => t.termsDocument.name === terms.termsDocument.name,
      );
    },
    showModal(terms) {
      this.selectedTerms = this.getCurrentTerms(terms);
      this.$refs[this.modalRef].show();
    },
    hideModal() {
      this.$refs[this.modalRef].hide();
      setTimeout(() => (this.selectedTerms = false), 100);
    },
  },
  computed: {
    ...mapState(['individualizedTerms', 'isLoading']),
    modalRef() {
      return `terms-modal`;
    },
  },
};
</script>

<style lang="scss">
.sign-terms__col {
  + .sign-terms__col {
    margin-top: 1rem;

    @include min-width(md) {
      margin-top: 0;
    }
  }
}

.sign-terms__pdf {
  margin-top: 1rem;
  height: 80vh;
  width: 100%;
  border: rem(1px) solid $color-grey-border;
  border-radius: $border-radius;
}
</style>
